import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import IconPerson from "../assets/images/staff-icon.svg";
import IconBicycle from "../assets/images/van-icon.svg";
import IconVan from "../assets/images/bike-icon.svg";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiClient from "../services/api.js";
import * as CryptoJS from "crypto-js";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NavBar from "./NavBar.jsx";

export default function GrandioseMain() {
  const useStyles = makeStyles(() => ({
    container: {
      padding: "50px 0",
    },
    avatar: {
      height: "auto",
      width: "calc(33.33% - 30px)",
      minWidth: "75px",
      maxWidth: "200px",
      border: "3px solid",
      cursor: "pointer",
    },
    separator: {
      marginTop: "45px",
      marginBottom: "45px",
      height: "1px",
      width: "100%",
      backgroundColor: "#dddddd",
    },
  }));
  const navigate = useNavigate();
  const classess = useStyles();
  const theme = useTheme();
  const [actionsArray] = useState({
    deliveryTypes: [
      {
        id: 1,
        fieldName: "Staff",
        icon: IconPerson,
      },
      {
        id: 2,
        fieldName: "Bike",
        icon: IconBicycle,
      },
      {
        id: 3,
        fieldName: "Van",
        icon: IconVan,
      },
    ],
    deliverAccountCodes: [
      {
        id: 1,
        fieldName: "Instashop",
        code: "INSP",
      },
      {
        id: 2,
        fieldName: "Internal",
        code: "INTL",
      },
    ],
  });

  const [orderDetails, setOrderDetails] = useState({
    tempOrderId: "",
    orderNo: "",
    deliveryType: "",
    deliverNotes: "",
    deliverAccountCode: "", // the aggregator

    cancellationAllowed: "Y",
    shipmentOrderTypeCd: "DELIVER",
    orderState: "FORWARD",
    autoAllocateFl: "Y",
    shipmentOrderDt: "", // current date 2016-07-15T10:30:00.000Z
    distributionCenter: "", // DBJ2RS
    deliverBranch: "", // Jumeirah Retail Store
    deliverAccountName: "", // Hitesh Agrawal
    deliverPhoneNumber: "", // 3125096995
    returnBranch: "", // Jumeirah Retail Store
    deliverEndTimeWindow: "", // 2021-11-02T10:31:00.000Z plus 45 min
    deliverStartTimeWindow: "", // 2021-11-02T10:31:00.000Z plus 10 min
    deliverCountry: "UAE",
    deliverApartment: "", // Silicon Gate 1
    deliverStreetName: "", // DSO
    deliverCity: "", // Dubai
  });
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState({
    full_name: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const [alertMessage, setAlertMessage] = useState({
    type: "success",
    status: false,
    message: "Error please try again later",
  });

  /**
   * Did mount
   */
  useEffect(() => {
    setLoading(false);
    let localData = {
      token: localStorage.getItem("grandiose_en")
        ? localStorage.getItem("grandiose_en")
        : null,
      email: localStorage.getItem("grandiose_er")
        ? localStorage.getItem("grandiose_er")
        : null,
    };
    if (localData.token !== null && localData.email !== null) {
      let browserData = {
        decrypted_token: CryptoJS.AES.decrypt(
          localData.token,
          "grandiose"
        ).toString(CryptoJS.enc.Utf8),
        decrypted_email: CryptoJS.AES.decrypt(
          localData.email,
          "grandiose"
        ).toString(CryptoJS.enc.Utf8),
      };
      if (user.full_name === "") {
        console.log("browserData", browserData);
        apiClient.get("/sanctum/csrf-cookie").then((response) => {
          apiClient
            .post("api/user", browserData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${browserData.decrypted_token}`,
              },
            })
            .then((response) => {
              // handleIfLoggedin(true);
              if (!response.data) {
                console.log("cannot fetch user account details");
                navigate("/login");
              } else {
                console.log("user login success");
                setUser({ ...user, ...response.data });
                setOrderDetails({
                  ...orderDetails,
                  distributionCenter: response.data.branch_code,
                  deliverBranch: response.data.branch_name,
                  deliverAccountName: response.data.full_name,
                  deliverPhoneNumber: response.data.phone,
                  returnBranch: response.data.branch_name,
                  deliverCity: response.data.city,
                });
              }
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log("error fetching user");
              console.log(error.response);
              navigate("/login");
            });
        });
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate, orderDetails]);

  /**
   * Methods
   */

  function clearForm() {
    setOrderDetails({
      tempOrderId: "",
      orderNo: "",
      deliveryType: "",
      deliverNotes: "",
      deliverAccountCode: "", // the aggregator

      cancellationAllowed: "Y",
      shipmentOrderTypeCd: "DELIVER",
      orderState: "FORWARD",
      autoAllocateFl: "Y",
      shipmentOrderDt: "", // current date 2016-07-15T10:30:00.000Z
      distributionCenter: "", // DBJ2RS
      deliverBranch: "", // Jumeirah Retail Store
      deliverAccountName: "", // Hitesh Agrawal
      deliverPhoneNumber: "", // 3125096995
      returnBranch: "", // Jumeirah Retail Store
      deliverEndTimeWindow: "", // 2021-11-02T10:31:00.000Z plus 45 min
      deliverStartTimeWindow: "", // 2021-11-02T10:31:00.000Z plus 10 min
      deliverCountry: "UAE",
      deliverApartment: "", // Silicon Gate 1
      deliverStreetName: "", // DSO
      deliverCity: "", // Dubai
    });
  }

  function selectDeliveryType(type) {
    setOrderDetails({ ...orderDetails, deliveryType: type });
  }
  function selectAccountCode(aggName) {
    setOrderDetails({ ...orderDetails, deliverAccountCode: aggName });
  }

  // It will be combination of first 3 char of aggregator name and order number
  function inputOrderId(orderID) {
    // let combinedOrderNumber =
    //   orderDetails.deliveryType.substring(0, 3) + orderID;
    // combinedOrderNumber = combinedOrderNumber.toUpperCase();

    // Remove spaces
    let inputOrderID = orderID.replace(/\s/g, "");
    setOrderDetails({ ...orderDetails, tempOrderId: inputOrderID });
  }
  function inputDeliveryNotes(notes) {
    setOrderDetails({ ...orderDetails, deliverNotes: notes });
  }

  useEffect(() => {
    if (submitting === true) {
      setLoading(true);
      console.log("submitting", orderDetails);
      // setTimeout(() => {
      //   clearForm();
      //   setLoading(false);
      //   setSubmitting(false);
      // }, 500);
      // return false;
      axios
        .post(
          "https://api.loginextsolutions.com/ShipmentApp/mile/v2/create",
          [orderDetails],
          {
            headers: {
              "Content-Type": "application/json",
              "WWW-Authenticate": "BASIC c7456dba-0d47-4745-8a99-c662629715cc",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setSubmitting(false);
          if (response.data.hasError === true) {
            setAlertMessage({
              type: "error",
              status: true,
              message: response.data.error[0].errorList[0].message,
            });
          } else {
            clearForm();
            setAlertMessage({
              type: "success",
              status: true,
              message: response.data.message,
            });
          }
          console.log("response.data", response.data);
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage({
            type: "error",
            status: true,
            message:
              "Error while processing your request. Please try again later.",
          });
          console.log(error);
        });
    }
  }, [submitting]);

  /**
   * Submit Order
   */
  const submit = () => {
    let currentDate = new Date();

    let updatedStartTimeWindow = new Date();
    updatedStartTimeWindow.setMinutes(updatedStartTimeWindow.getMinutes() + 10);

    let updatedEndTimeWindow = new Date();
    updatedEndTimeWindow.setMinutes(updatedEndTimeWindow.getMinutes() + 45);

    let combinedOrderNumber = "";
    let generatedOrderNo = actionsArray.deliverAccountCodes.map((agg) => {
      let c = "";
      if (agg.fieldName === orderDetails.deliverAccountCode) {
        c = agg.code + "-" + orderDetails.tempOrderId;
      } else {
        c = "";
      }
      return c;
    });
    combinedOrderNumber = generatedOrderNo
      .filter(function (e) {
        return e !== "";
      })[0]
      .toUpperCase();

    setOrderDetails({
      ...orderDetails,
      orderNo: combinedOrderNumber,
      distributionCenter: user.branch_code,
      deliverBranch: user.branch_name,
      deliverAccountName: user.full_name,
      deliverPhoneNumber: user.phone,
      deliverApartment: user.apartment,
      deliverStreetName: user.street_name,
      returnBranch: user.branch_name,
      deliverCity: user.city,
      deliverStartTimeWindow: updatedStartTimeWindow,
      deliverEndTimeWindow: updatedEndTimeWindow,
      shipmentOrderDt: currentDate,
    });
    setSubmitting(true);
  };

  return (
    <div>
      <NavBar />
      <Container sx={{ pt: 5, pb: 10 }} maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {actionsArray.deliveryTypes.map((type) => (
            <Card
              key={type.id}
              className={classess.avatar}
              sx={{
                borderColor:
                  orderDetails.deliveryType === type.fieldName
                    ? theme.palette.secondary.main
                    : "primary",
                borderRadius: 0,
              }}
              onClick={() => selectDeliveryType(type.fieldName)}
            >
              <CardActionArea>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: "20px",
                  }}
                  className="avatar-box"
                >
                  <img
                    src={type.icon}
                    srcSet={type.icon}
                    alt={type.fieldName}
                    loading="lazy"
                    width="90%"
                    max-width="80px"
                    height="auto"
                  />
                  <Typography variant="h6" component="div" sx={{ mt: "auto" }}>
                    {type.fieldName}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
        <div className={classess.separator}></div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          {actionsArray.deliverAccountCodes.map((code) => (
            <Button
              disabled={orderDetails.deliveryType !== "" ? false : true}
              key={code.id}
              color={
                orderDetails.deliverAccountCode === code.fieldName
                  ? "secondary"
                  : "primary"
              }
              variant={
                orderDetails.deliverAccountCode === code.fieldName
                  ? "contained"
                  : "outlined"
              }
              sx={{ marginBottom: "15px", padding: "15px 0", borderRadius: 0 }}
              onClick={() => selectAccountCode(code.fieldName)}
            >
              {code.fieldName}
            </Button>
          ))}
        </Box>

        <Box noValidate autoComplete="off">
          {/* component="form"  */}
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Order id
            <Typography
              variant="caption"
              component="span"
              sx={{ marginLeft: "5px" }}
            >
              (required)
            </Typography>
          </Typography>
          <OutlinedInput
            disabled={
              orderDetails.deliveryType !== "" &&
              orderDetails.deliverAccountCode !== ""
                ? false
                : true
            }
            value={orderDetails.tempOrderId}
            sx={{ width: "100%", marginBottom: "30px", borderRadius: 0 }}
            placeholder="Please Order ID"
            onChange={(e) => inputOrderId(e.target.value)}
          />
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Deliver Notes
            <Typography
              variant="caption"
              component="span"
              sx={{ marginLeft: "5px" }}
            >
              (optional)
            </Typography>
          </Typography>
          <TextField
            sx={{ width: "100%", marginBottom: "15px", borderRadius: 0 }}
            inputProps={{ maxLength: 250 }}
            multiline
            value={orderDetails.deliverNotes}
            rows={3}
            placeholder="Optional"
            onChange={(e) => inputDeliveryNotes(e.target.value)}
          />
        </Box>
        <LoadingButton
          disabled={
            orderDetails.deliveryType !== "" &&
            orderDetails.deliverAccountCode !== "" &&
            orderDetails.tempOrderId !== ""
              ? false
              : true
          }
          loading={isLoading}
          variant="contained"
          sx={{
            marginTop: "5px",
            marginBottom: "15px",
            padding: "15px 0",
            width: "100%",
            borderRadius: 0,
          }}
          onClick={() => {
            submit();
          }}
        >
          Submit
        </LoadingButton>
        <Collapse in={alertMessage.status}>
          <Alert
            severity={alertMessage.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertMessage({ ...alertMessage, status: false });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage.message}
          </Alert>
        </Collapse>
      </Container>
    </div>
  );
}
