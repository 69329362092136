import * as React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import Icon from "@mui/material/Icon";

import Logo from "../assets/images/grandiose-logo.svg";
import DrawerBG from "../assets/images/gag-5.png";
import { useNavigate, NavLink } from "react-router-dom";
import apiClient from "../services/api.js";
import * as CryptoJS from "crypto-js";
const useStyles = makeStyles({
  logo: {
    margin: "0 auto",
    maxWidth: "150px",
    width: "100%",
  },
  navAnchor: {
    textDecoration: "none",
  },
});

export default function GrandioseHeader() {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  let localData = {
    token: localStorage.getItem("grandiose_en")
      ? localStorage.getItem("grandiose_en")
      : null,
    email: localStorage.getItem("grandiose_er")
      ? localStorage.getItem("grandiose_er")
      : null,
  };
  let browserData = {
    decrypted_token: localData.token
      ? CryptoJS.AES.decrypt(localData.token, "grandiose").toString(
          CryptoJS.enc.Utf8
        )
      : "",
    decrypted_email: localData.email
      ? CryptoJS.AES.decrypt(localData.email, "grandiose").toString(
          CryptoJS.enc.Utf8
        )
      : "",
  };
  function logout() {
    apiClient
      .post(
        "/api/sanctumlogout",
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${browserData.decrypted_token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        localStorage.removeItem("grandiose_er");
        localStorage.removeItem("grandiose_en");
        navigate("/login");
      })
      .catch((error) => {
        console.log("error logout request");
        console.log(error.response);
      });
  }

  const list = (anchor) => (
    <Box
      sx={{
        backgroundColor: "#000",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        backgroundImage: `url(${DrawerBG})`,
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          {
            label: "Submit Orders",
            icon: "add",
            link: "/",
          },
          // {
          //   label: "Users",
          //   icon: "manage_accounts",
          //   link: "/users",
          // },
          // {
          //   label: "logout",
          //   icon: "power_settings_new",
          //   link: "/logout",
          // },
        ].map((nav) => (
          <NavLink
            to={nav.link}
            key={`route-${nav.label}}`}
            className={classes.navAnchor}
          >
            <ListItem button sx={{ color: "white" }} key={nav.label}>
              <ListItemIcon sx={{ color: "white" }}>
                <Icon>{nav.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={nav.label} />
            </ListItem>
          </NavLink>
        ))}

        <ListItem
          onClick={() => {
            logout();
          }}
          className={classes.navAnchor}
          button
          sx={{ color: "white" }}
        >
          <ListItemIcon sx={{ color: "white" }}>
            <Icon>power_settings_new</Icon>
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img
            src={Logo}
            alt="Grandiose Supermarket"
            className={classes.logo}
          />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, position: "absolute", left: "auto", right: 0 }}
            onClick={toggleDrawer("right", true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </Box>
  );
}
