import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import apiClient from "../services/api.js";
import { useNavigate } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import LogoVertical from "../assets/images/Grandiose-vertical.svg";

export default function Users() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [alertMessage, setAlertMessage] = useState({
    status: false,
    message: "Error please try again later",
  });

  function submitLogin() {
    /**
     * Axios Login request
     * https://laravel-news.com/using-sanctum-to-authenticate-a-react-spa
     *
     */
    // {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    // }
    setLoading(true);
    let data = {
      email: loginDetails.email,
      password: loginDetails.password,
    };
    apiClient.get("/sanctum/csrf-cookie").then((response) => {
      console.log("sanctum/csrf-cookie", response);
      apiClient
        .post("api/sanctumlogin", data)
        .then((response) => {
          localStorage.setItem(
            "grandiose_en",
            CryptoJS.AES.encrypt(response.data.token.toString(), "grandiose")
          );
          localStorage.setItem(
            "grandiose_er",
            CryptoJS.AES.encrypt(
              response.data.user.email.toString(),
              "grandiose"
            )
          );
          setLoading(false);
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage({
            status: true,
            message: error.response.data.message,
          });
          console.log(error.response.data);
        });
    });
  }

  return (
    <div>
      <Box className="app-bg" sx={{ width: "100%", backgroundColor: "#000" }}>
        <Container sx={{ py: 4, height: "100vh" }} maxWidth="sm">
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto 40px",
              maxWidth: "140px",
            }}
          >
            <img src={LogoVertical} alt={"Grandiose Logo"} loading="lazy" />
          </Box>
          <Card
            elevation={0}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                sx={{ mb: 3, color: "white", textAlign: "center" }}
              >
                LOG IN
              </Typography>
              <TextField
                sx={{ backgroundColor: "#ffffff", mb: 3 }}
                size="medium"
                label="E-mail"
                onChange={(e) =>
                  setLoginDetails({ ...loginDetails, email: e.target.value })
                }
                variant="filled"
              />
              <TextField
                sx={{ backgroundColor: "#ffffff", mb: 3 }}
                size="medium"
                label="Password"
                type="password"
                onChange={(e) =>
                  setLoginDetails({ ...loginDetails, password: e.target.value })
                }
                variant="filled"
              />

              <LoadingButton
                loading={isLoading}
                classes={{ disabled: "gDisabledButton" }}
                disabled={
                  loginDetails.email !== "" && loginDetails.password !== ""
                    ? false
                    : true
                }
                color={
                  loginDetails.email !== "" && loginDetails.password !== ""
                    ? "secondary"
                    : "primary"
                }
                variant={
                  loginDetails.email !== "" && loginDetails.password !== ""
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  marginBottom: "15px",
                  padding: "15px 0",
                  borderRadius: 0,
                }}
                onClick={() => submitLogin()}
              >
                Login
              </LoadingButton>
              <Collapse in={alertMessage.status}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertMessage({ ...alertMessage, status: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {alertMessage.message}
                </Alert>
              </Collapse>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
}
