import axios from "axios";

let apiUrl = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  apiUrl = "http://127.0.0.1:8000";
} else {
    apiUrl = "http://loginextuatadmin.grandiose.ae";
}
// apiUrl = "http://127.0.0.1:8000";
// apiUrl = "http://loginextuatadmin.grandiose.ae";
apiUrl = "https://loginext.melinfinity.com";

const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

export default apiClient;
